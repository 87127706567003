<template>
  <div class="vertical-step">
    <div class="vertical-step-circle" :style="darkMode ? 'background-color: #82B1FF' : 'background-color: #334a58'">
      <span :style="darkMode ? 'color: black' : 'color: white'">{{ number }}</span>
    </div>
    <span class="color-gray"> {{ text }} </span>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    darkMode() {
      return localStorage.getItem('dark_theme') === 'true'
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-step {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: bold;
}

.vertical-step-circle {
  background: #334a58;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.color-gray {
  color: #00000099;
}
</style>
